import { Component, Prop, Vue, Watch, Emit } from "vue-property-decorator";
import { Getter, Action } from "vuex-class";
import type { PropertyInfoBlockValue } from "@/mixins/properties/has-config";

@Component({
  components: {
    PropertyDesktopWrapper: () => import("@/components/properties/desktop/wrapper.vue"),
    FormSelect: () => import("@/components/forms/select.vue"),
    FormInput: () => import("@/components/forms/input.vue"),
    FormPhone: () => import("@/components/forms/phone.vue"),
    FormYesNo: () => import("@/components/forms/yes-no.vue"),
    FormMoney: () => import("@/components/forms/money.vue"),
    FormTextarea: () => import("@/components/forms/textarea.vue"),
    FormDate: () => import("@/components/forms/date.vue"),
    FormWysiwyg: () => import("@/components/forms/wysiwyg.vue"),
    FormFile: () => import("@/components/forms/file.vue"),
  },
})
export class HasDesktopPart extends Vue {
  @Prop({ required: true }) label!: string;
  @Prop({ required: false }) labelClass!: string;
  @Prop({ required: false }) name!: string;
  @Prop({ required: false, default: "" }) rules!: string;
  @Prop({ required: false, default: false }) disabled!: boolean;
  @Prop({ required: false, default: false }) required!: boolean;
  @Prop({ required: false, default: false }) nullable!: boolean;
  @Prop({ required: false }) suffix!: string;
  @Prop({ default: 2 }) cols!: 1 | 2;

  localValue: any | null = null;

  @Emit("input")
  handleInput() {
    return this.localValue;
  }

  @Watch("value", { immediate: true })
  valueChanged(newValue: any | null) {
    this.localValue = newValue;
  }
}
